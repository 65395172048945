import React, { useEffect } from "react";
import { graphql } from "gatsby";

// components
import { SortedCards, CollectionsLinks, Seo } from "../components";
import Layout from "../components/Layout";

const TagTemplate = ({ data, pageContext }) => {
  const books = data.tags.nodes;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  return (
    <Layout>
      <Seo title={pageContext.tag} />
      <CollectionsLinks />
      <SortedCards books={books} title={`# ${pageContext.tag}`} />
    </Layout>
  );
};

export const query = graphql`
  query GetTag($tag: String!) {
    tags: allAirtable(
      filter: { data: { tags: { eq: $tag }, status: { eq: "Available" } } }
      sort: { fields: data___Release_Date, order: DESC }
    ) {
      nodes {
        data {
          ...CardParts
        }
      }
    }
  }
`;

export default TagTemplate;
